import {COMPANY_TYPE} from '@/constants';
import {getUserConfig} from '@/generated/monitor-api';
import {
  Company,
  CompanyNodeDto,
  CompanyType,
  UserConfiguration
} from '@/generated/monitor-api.schemas';
import {getAccountsBaseUrl} from '@/helpers/urls';
import {debugSubCompany as subCompanyLogger} from '@/logger';
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction
} from '@reduxjs/toolkit';
import axios, {InternalAxiosRequestConfig} from 'axios';
import qs from 'qs';
import {SUB_COMPANY_PARAM} from './monitorConfig';

const debugSubCompany = subCompanyLogger('monitorConfig');
const debugInterceptor = subCompanyLogger('axios-interceptor');

export const readSubCompanyFromLocation = (location = window.location) => {
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  // includeSubCompanies should default to true if not set in the url
  const includeSubs = query?.includeSubCompanies !== 'false';
  return [query?.subCompany, includeSubs];
};

// holds existing subCompany axios interceptors
let interceptors: number[] = [];

/**
 * Registers an axios interceptor which appends the subCompany
 * parameter to all backend requests.
 *
 * @param selectedCompanies list of uuids of the selected companies
 */
const registerAxiosInterceptor = (selectedCompanies: string[]) => {
  // make sure if the company changes, we remove all other possibly existing interceptors

  interceptors.forEach(existing => axios.interceptors.request.eject(existing));
  interceptors = [];
  debugInterceptor(`Interceptors cleared, size is ${interceptors.length}`);
  debugInterceptor(
    'Registering axios interceptor for companies %O',
    selectedCompanies
  );

  // Important: /api/config/monitor/connector-count needs the subCompany parameter!
  const excludedUrls = [
    '/api/user',
    '/api/newsFeedback/config',
    '/api/newsFeedback/ssoToken' /*, '/api/config'*/
  ];
  const interceptor = (config: InternalAxiosRequestConfig) => {
    const isExcluded = excludedUrls.find(
      exclude => config.url && config.url?.indexOf(exclude) > -1
    );
    if (
      // only inject it into our api calls
      (config.url && config.url.indexOf('/api') === -1) ||
      isExcluded
    ) {
      debugInterceptor(
        `URL excluded, not intercepting request to ${config.url}`
      );

      return config;
    }

    if (config?.params?.includeSubCompanies) {
      // the backend is not interested in this
      delete config?.params?.includeSubCompanies;
    }

    if (Array.isArray(selectedCompanies) && selectedCompanies.length > 0) {
      if (!config.params) {
        config.params = {};
      }

      // allow to override this behavior for specific request (e.g. when
      // creating tickets in elena we need a single subCompany in the request)
      if (config.params[SUB_COMPANY_PARAM]) {
        debugSubCompany(
          `subCompany param already configured, using existing one: ${config.params[SUB_COMPANY_PARAM]}`
        );
      } else {
        config.params[SUB_COMPANY_PARAM] = selectedCompanies.join(',');
      }
    }

    return config;
  };

  const interceptorId = axios.interceptors.request.use(interceptor);
  interceptors.push(interceptorId);
};

interface PartnerUserDto {
  userId: string;
  authorities: string[];
}

interface PartnerRelation {
  uuid: string;
  customer: Company;
  authorities: string[];
  users: PartnerUserDto[];
}

export interface UserConfig extends UserConfiguration {
  selectedSubCompany: string;
  selectedSubCompanyName: string;
  includeSubCompanies: boolean;
  selectedCompanies: string[];
  credentialsAuthAllowed: boolean;
  credentialsAuthSupported: boolean;
}

interface UserConfigState {
  loading: boolean;
  error: any | null;
  errorStatus: number | null;
  authServer: string;
  isPartner: boolean;
  allCompanies: CompanyNodeDto[];
  partnerRelations: PartnerRelation[];
  companyNode: CompanyNodeDto;
  userConfig: UserConfig;
}

export const initialState: UserConfigState = {
  loading: true,
  error: null,
  errorStatus: null,
  /**
   * a flat list of all companies selectable by the current user.
   * Can change during runtime in case of partner relations when a user
   * changes the partner relation.
   * */
  allCompanies: [],
  /**
   * All partner relations active for the currently logged user. Will be empty for STANDARD companies.
   */
  partnerRelations: [],
  /**
   * The active company tree. This is static for STANDARD companies, and can change
   * for PARTNER companies.
   */
  companyNode: {
    uuid: '',
    name: '',
    subCompanies: [],
    level: 0,
    hasParent: false
  },
  authServer: '',
  isPartner: false,
  userConfig: {
    timeZone: '',
    eventSourceEnabled: true,
    userLocale: '',
    supportedLocales: [],
    theme: '',
    firstName: '',
    lastName: '',
    supportChatEnabled: false,
    // subCompanies: [],
    authorities: [],
    email: '',
    company: {
      name: '',
      companyType: CompanyType.STANDARD,
      uuid: ''
    },
    companyNode: {
      uuid: '',
      name: '',
      subCompanies: [],
      level: 0,
      hasParent: false
    },
    partnerRelations: [],
    // the UUID of the selected company from the dropdown
    selectedSubCompany: '',
    // the name of the selected company from the dropdown
    selectedSubCompanyName: '',
    // if includeSubCompanies has been checked in the sub-company dropdown
    includeSubCompanies: true,
    // resolved companyUuids of all selected companies, taking includeSubCompanies into account
    selectedCompanies: [],
    credentialsAuthAllowed: true,
    credentialsAuthSupported: true,
    ediRelationsEnabled: true
  }
};

export type KnownError = {
  error: string;
  description: string;
  status: number | undefined;
};

const parseModules = (rawString: string) => {
  try {
    if (Array.isArray(rawString)) {
      return rawString;
    }

    if (typeof rawString === 'undefined') {
      return [];
    }

    return JSON.parse(rawString);
  } catch (error) {
    if (!areWeTestingWithinTest()) {
      console.error(error);
    }
    return [];
  }
};

function areWeTestingWithinTest() {
  return process.env.VITEST_WORKER_ID !== undefined;
}

export const getEnabledModules = async () => {
  try {
    const result = await axios.get('/api/config/enabled-modules');
    return parseModules(result.data);
  } catch (error) {
    if (!areWeTestingWithinTest()) {
      console.error(error);
    }
    return [];
  }
};

export const fetchUserConfig = createAsyncThunk<
  UserConfiguration,
  void,
  {rejectValue: KnownError}
>('config/fetch', async (userData, thunkAPI) => {
  try {
    const [userConfig, enabledModules] = await Promise.all([
      getUserConfig().then(response => response.data),
      // TODO: move to customer-layout and get rid of it here
      getEnabledModules()
    ]);

    return Object.assign(userConfig, {modules: enabledModules});
  } catch (error) {
    // @ts-expect-error axios
    return thunkAPI.rejectWithValue(error?.response || error);
  }
});

/**
 * Gets the company node with the given companyUuid from the given node.
 *
 * @param companyUuid The companyUuid to search for
 * @param node The company tree to search in
 * @returns the companyNode for the given companyUuid or undefined
 */
export const getSubNode = (companyUuid: string, node: CompanyNodeDto) => {
  const getNode = (subNode: CompanyNodeDto): CompanyNodeDto | undefined => {
    if (subNode.uuid === companyUuid) {
      return subNode;
    }

    if (!subNode.subCompanies?.length) {
      return;
    }

    let i;
    let result;

    for (
      i = 0;
      typeof result === 'undefined' && i < subNode.subCompanies.length;
      i++
    ) {
      result = getNode(subNode.subCompanies[i]);
    }
    return result;
  };

  return getNode(node);
};

/**
 * Receives a CompanyNode and flattens it to a list of company nodes.
 */
export const flattenCompanyNode = (node: CompanyNodeDto): CompanyNodeDto[] => {
  const companies: CompanyNodeDto[] = [];

  const addSubCompanies = (innerNode: CompanyNodeDto) => {
    companies.push(innerNode);

    if (!innerNode.subCompanies?.length) {
      return;
    }

    innerNode.subCompanies.forEach((subCompany: CompanyNodeDto) =>
      addSubCompanies(subCompany)
    );
  };

  addSubCompanies(node);
  return companies;
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    /**
     * Changes the currently selected sub-company.
     * Will unregister the old axios interceptor and register
     * the new one for attaching the selected companyUuids to each backend request.
     *
     * @param state
     * @param payload
     */
    changeSubCompany: (state, {payload}) => {
      const {
        companyUuid,
        // optional
        includeSubCompanies = true
      } = payload;

      const currentCompanyNode = current(state).companyNode;
      let selectedNode = getSubNode(companyUuid, currentCompanyNode);

      if (!selectedNode) {
        selectedNode = currentCompanyNode;
      }

      const selectedCompanies = includeSubCompanies
        ? flattenCompanyNode(selectedNode).map(n => n.uuid)
        : [companyUuid];

      const newSubCompanyName = selectedNode.name;
      const newSubCompanyUuid = selectedNode.uuid;

      // This is crucial: We MUST handle the axios interceptor change
      // BEFORE this reducer mutates the store, otherwise we might
      // run into race conditions.
      registerAxiosInterceptor(selectedCompanies);

      debugSubCompany(
        `Dispatching CHANGE_SUB_COMPANY action with ${newSubCompanyName} and ${newSubCompanyUuid}`
      );

      state.userConfig.selectedSubCompany = newSubCompanyUuid || '';
      state.userConfig.selectedSubCompanyName = newSubCompanyName || '';
      state.userConfig.includeSubCompanies = includeSubCompanies;
      state.userConfig.selectedCompanies = selectedCompanies;
    },
    /**
     * Changes the current company tree. Only used by PARTNER companies who
     * can switch the company context they are using the monitor in.
     *
     * @param state
     * @param payload a CompanyNode with the new tree
     */
    setCompanyNode: (state, action: PayloadAction<CompanyNodeDto>) => {
      const allCompanies = flattenCompanyNode(action.payload);
      // @ts-expect-error TODO
      state.companyNode = action.payload;
      // @ts-expect-error TODO
      state.allCompanies = allCompanies;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserConfig.pending, state => {
        Object.assign(state, initialState);
      })
      .addCase(fetchUserConfig.fulfilled, (state, action) => {
        state.loading = false;

        // @ts-expect-error we add 4 fields to the UserConfig on the client, hence the ts-ignore
        state.userConfig = action.payload;

        const isPartner =
          action.payload.company.companyType === COMPANY_TYPE.PARTNER;

        const allCompanies = flattenCompanyNode(action.payload.companyNode);

        // these 2 properties can be mutated in partner relations using the setCompanyNode action above
        // @ts-expect-error TODO
        state.allCompanies = allCompanies;
        // @ts-expect-error TODO
        state.companyNode = action.payload.companyNode;

        // backwards compatibility to @ecosio/auth config reducer
        state.authServer = getAccountsBaseUrl();
        state.isPartner = isPartner;

        const newSubCompanyUuid =
          action.payload.selectedSubCompany || action.payload.company.uuid;
        const newSubCompanyName =
          action.payload.selectedSubCompanyName || action.payload.company.name;

        const [, includeSubCompanies] = readSubCompanyFromLocation();
        state.userConfig.selectedSubCompany = newSubCompanyUuid;
        state.userConfig.selectedSubCompanyName = newSubCompanyName;
        state.userConfig.includeSubCompanies = includeSubCompanies as boolean;

        let selectedNode = getSubNode(newSubCompanyUuid, state.companyNode);

        if (!selectedNode) {
          selectedNode = state.companyNode;
        }

        const selectedCompanies = includeSubCompanies
          ? flattenCompanyNode(selectedNode).map(n => n.uuid)
          : [newSubCompanyUuid];

        registerAxiosInterceptor(selectedCompanies);
        state.userConfig.selectedCompanies = selectedCompanies;
      })
      .addCase(fetchUserConfig.rejected, (state, action) => {
        Object.assign(state, initialState);
        state.loading = false;
        state.error = action.error;

        switch (action.payload?.status) {
          case 401:
            state.errorStatus = 401;
            break;
          case 403:
            state.errorStatus = 403;
            break;
          default:
            console.error(action.error);
            state.errorStatus = null;
        }
      });
  }
});

export const {changeSubCompany, setCompanyNode} = slice.actions;

export default slice.reducer;
